<template>
    <div class="wrap">
        <LoadingProgress v-show="loading" :loadingValue="loadedAssets/neededAssets * 100"  />
        <DialogFrame id='dialog-logout-frame' ref='dialogLogout' :needClose='true' @emitConfirm='confirmLogout'>
            <div class="dialog-logout" slot='dialog-content'>
                <h6>Logout</h6>
                <div class="span-box">
                    <span>Come back soon!</span>
                    <span>Are you sure you want ot Log out? </span>
                </div>
            </div>
        </DialogFrame>

        <transition name="el-fade-in-linear">
            <div class="mask" v-show="extendMenu" @click="extendMenu=false"></div>
        </transition>
        
        <transition name="el-fade-in-linear">
            <div class="exhibition-intro" v-show="introToggle">
                <div class="pop-up-header">Introduction
                    <div class="close-btn" @click="introToggle=false">
                        <img src="@/assets/img/icon/crossW.svg" alt="" />
                    </div>
                </div>
                <div class="intro-content">
                    <div class="intro-p">
                        <p>{{introduction}}</p>
                    </div>
                    <div class="close-btn-spec" @click="introToggle=false">Close</div>
                </div>
            </div>
        </transition>
        <!-- 主要內容 -->
        <canvas id='indoor-scene'></canvas>

        <transition name="el-fade-in-linear">
            <div class="pop-up-tv" v-show="tvToggle">
                <div class="pop-up-header">{{tvName}}
                <div class="close-btn" @click="tvToggle = false;">
                    <img src="@/assets/img/icon/crossW.svg" alt="" />
                </div>
                </div>
                <iframe
                    id="tv-screen"
                    :src="tvUrl"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </transition>

        <div class="pop-up-scene" v-show="popUpCanvasToggle">
            <div class="pop-up-header">Model Name
            <div class="close-btn" @click="closePopScene">
                <img src="@/assets/img/icon/crossW.svg" alt="" />
            </div>
            </div>
            <canvas id='pop-up-canvas'></canvas>
            <div class="hint">
                <div class="img-box mouse">
                    <img src="@/assets/img/icon/mouse.svg" alt="" class="mouse">
                    <span>Left-click to rotate, right-click to move and scroll wheel to zoom the screen</span>
                </div>
               <div class="img-box cube">
                    <img src="@/assets/img/icon/uCube.svg" alt="">
                    <span>Click on cube can see the description</span>
                </div>
            </div>
        </div>

        <div class="pop-up-board" v-show="boardToggle">
            <div class="close-btn" @click="boardToggle=false">
                <img src="@/assets/img/icon/crossW.svg" alt="" />
            </div>
            <!--<el-image :src="attachedBoardImg" class='board-img' fit='cover'></el-image> -->
            <iframe id="myFrame" :src='attachedWebsite'  class='board-img' fit='cover'></iframe>
        </div>

        <div class="lobby-bar menu-bar">
            <div class="name-box pointer">
                <span v-if="playerID"><strong>{{playerID}}@{{exhibitionData.title}}</strong><br><span class="room-title">{{exhibitionData.roomName}}</span></span>
                <span v-if="!playerID"><strong>{{exhibitionData.title}}</strong><br><span class="room-title">{{exhibitionData.roomName}}</span></span>
                <img src="@/assets/img/icon/location.svg" alt="">
            </div>
            <div class="icon-box pointer" @click='extendMenu=!extendMenu;'>
                <img src="@/assets/img/icon/menu.svg" alt="">
                <span>More</span>
            </div>
            
            <!-- <div class="icon-box pointer" @click='backToEntrance'>
                <img src="@/assets/img/icon/logout.svg" alt="">
                <span>Exit</span>
            </div> -->
            <!-- extended menu slots start -->
            <div class="extended-menu exhibition" v-show="extendMenu" :class="{hasLogin: login}">

                <div class="icon-box pointer floater" @click='switchMenuState(MENU_STATE.BLOCK, false)'>
                    <span>Block Info</span>
                    <img src='@/assets/img/icon/block-v2.svg' alt="">
                    <img src='@/assets/img/icon/block-v2_active.svg' alt="">
                </div>
                <div class="icon-box pointer floater" @click='switchMenuState(MENU_STATE.PLAYER_ID, false)'>
                    <span>Player ID</span>
                    <img src='@/assets/img/icon/block-v2.svg' alt="">
                    <img src='@/assets/img/icon/block-v2_active.svg' alt="">
                </div>
                <!-- <div class="icon-box pointer floater" @click='shotPhoto'>
                    <span>Take Photo</span>
                    <img src='@/assets/img/icon/photo.png' alt="">
                    <img src='@/assets/img/icon/photo_active.png' alt="">
                </div> -->

                <div class="icon-box pointer floater" @click='switchMenuState(MENU_STATE.CHAT)'>
                    <span>Chat</span>
                    <img src='@/assets/img/icon/acc-info.png' alt="">
                    <img src='@/assets/img/icon/acc-info_active.png' alt="">
                </div>
                <!-- <div class="icon-box pointer floater" @click='logoutDialog'>
                    <span>{{login?'Logout':'Login'}}</span>
                    <img :src="login?require('@/assets/img/icon/logout.png'):require('@/assets/img/icon/login.svg')" alt="">
                    <img src="@/assets/img/icon/logout_active.png" alt="">
                </div> -->
            </div>
            <!-- extended menu slots end -->
            <transition name="el-fade-in-linear">
                <div class="main-menu" v-show="menuToggle&&!extendMenu">
                    <div class="title">{{menuTitle}}</div>
                    <!--  -->
                    <div class="block-item" v-show="menuState===MENU_STATE.BLOCK">
                        <div class="block-upper">
                            <img :src="exhibitionData.signboardUrl" alt="">
                            <div class="details">
                                <div class="name">{{exhibitionData.title}}</div>
                                <div class="theme"><img src="@/assets/img/icon/loc.svg" alt="">Theme - {{themeData.name}}</div>
                                <div><img src="@/assets/img/icon/email.svg" alt="">{{exhibitionData.email}}</div>
                                <div><img src="@/assets/img/icon/call.svg" alt="">{{exhibitionData.phone}}</div>
                            </div>
                        </div>
                        <div class="block-lower">{{exhibitionData.introduction}}</div>
                        <!-- <div class="btn-block">
                            <button class="pointer" @click='backToEntrance'>Exit Block</button>
                            <button class="pointer" @click='switchMenuState(MENU_STATE.CONTACT)'>Email
                                <img src="@/assets/img/icon/arr.svg" alt="">
                            </button>
                        </div> -->
                    </div>
                    <!--  -->
                    <div class="contact-item" v-show="menuState===MENU_STATE.CONTACT">
                        <div class="block-details">
                            <span class="name">{{exhibitionData.title}}</span>
                            <div class="contact-box">
                                <div class="email"><img src="@/assets/img/icon/email.svg" alt="">{{exhibitionData.email}}</div>
                                <div class="phone"><img src="@/assets/img/icon/call.svg" alt="">{{exhibitionData.phone}}</div>
                            </div>
                        </div>
                        <div class="name-form">
                            <div class="form-box">
                                <span>First name<span class='star'>*</span></span>
                                <input type="text" name="firstName" id="firstName" v-model="form.firstName" autocomplete="off">
                            </div>
                            <div class="form-box">
                                <span>Last name<span class='star'>*</span></span>
                                <input type="text" name="lastName" id="lastName" v-model="form.lastName" autocomplete="off">
                            </div>
                        </div>
                        <div class="email-box">
                            <span>Email<span class='star'>*</span></span>
                            <input type="email" name="email" id="email" v-model="form.email" autocomplete="off">
                        </div>
                        <div class="message-box">
                            <span>Message<span class='star'>*</span></span>
                            <textarea placeholder="type your message here2..." v-model="form.message" name="message" id="message"></textarea>
                        </div>
                        <div class="btn-block">
                            <button @click='switchMenuState(MENU_STATE.BLOCK)'>Cancel</button>
                            <button @click='sendMessage()'>Send!<img src="@/assets/img/icon/arr.svg" alt=""></button>
                        </div>
                        <transition name="el-fade-in-linear">
                            <div class="messages fail" v-show="showMessage===MESSAGE_STATE.FAIL">
                                <img src="@/assets/img/icon/fail.svg" alt="">
                                <span class="fail">Fail. {{login ? 'Please try again.' : 'Login required.'}}</span>
                            </div>
                        </transition>
                        <transition name="el-fade-in-linear">
                            <div class="messages success" v-show="showMessage===MESSAGE_STATE.SUCCESS">
                                <img src="@/assets/img/icon/success.svg" alt="">
                                <span class="success">Successfully sent!</span>
                            </div>
                        </transition>
                    </div>
                    <!--  -->
                    <div class="chat-item" v-show="menuState===MENU_STATE.CHAT">
                        <div class="chat-tags">
                            <div class="chats pointer" :class="{active:channel=='system'}" @click="channel='system'">System</div>
                            <div class="chats pointer" :class="{active:channel=='chat'}" @click="channel='chat'">Chat</div>
                        </div>
                        <div class="chat-box" v-show="channel=='chat'">
                            <div v-show="chatroomData.length" class="chat-container" v-for="item in chatroomData" :key="item.index" :class="{self:item.name===nickname}">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="chat-box" v-show="channel=='system'">
                            <div v-show="systemData.length" class="chat-container" v-for="item in systemData" :key="item.index">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="send">
                            <input type="text" name='chatMessage' v-model="chatMessage" placeholder="Say something..." autocomplete="off" v-on:keydown='keydownEvent'>
                            <img class='pointer' src="@/assets/img/icon/send.svg" alt="" @click="sendChatMessage">
                        </div>
                    </div>
                    <!--  -->
                    <div class="account-item" v-show="menuState===MENU_STATE.ACCOUNT_INFO">
                        <div class="edit-box">
                            <div class="info-box">
                                <span>Account ID</span>
                                <span>Saha12345678</span>
                            </div>
                            <div class="info-box">
                                <span>User Name</span>
                                <span>Saha</span>
                                <span>Edit</span>
                            </div>
                            <div class="info-box">
                                <span>Email</span>
                                <span>fake@gmail.com</span>
                                <span>Edit</span>
                            </div>
                            <div class="info-box">
                                <span>Password</span>
                                <span>************</span>
                                <span>Edit</span>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="account-item" v-show="false">
                        <div class="form-box">
                            <span>First name<span class='star'>*</span></span>
                            <input type="text" name="firstName" id="firstName" v-model="form.firstName" autocomplete="off">
                        </div>
                    </div>
                    <div class="playerid-item" v-show="menuState===MENU_STATE.PLAYER_ID">
                        <div class="block-upper">
                            <div class="playerid-item" v-show="menuState===MENU_STATE.PLAYER_ID">
                                <div class="block-upper">
                                    <div class="form-box">
                                        <span>Player ID<span class='star'>*</span></span>
                                        <input type="text" name="playerID" id="playerID" v-model="playerID" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                     
                    </div>
                    <img src="@/assets/img/icon/close.svg" alt="" class="close pointer" @click='menuToggle=false'>
                </div>
            </transition>
           
        </div>



    </div>
</template>

<style lang='scss'>
@import '@/assets/scss/exhibition.scss';
</style>

<script>

import LoadingProgress from "@/components/LoadingProgress";
import DialogFrame from "@/components/DialogFrame.vue";
import * as Babylon from '@babylonjs/core';
import * as utils from "@/utils/main.js";
import * as Ammo from "ammo.js";
// import * as cannon from "cannon";
import "@babylonjs/loaders";//Load External Assets.
import { Inspector } from '@babylonjs/inspector';
import { mapActions } from 'vuex';
import {getSellerExhibition, HostUrl, sendMail, getUserAccByAdmin, exhInteract, checkTicket, exhEXP} from '@/api';
import axios from "axios";

export default {
    components:{
        LoadingProgress, DialogFrame
    },
    data(){
        return{
            //環境資料
            hostUrl: HostUrl,
            baseURL: process.env.BASE_URL,
            loading: true,
            extendMenu: false,
            introToggle: false,
            introduction:'',
            tvToggle: false,
            tvName: '',
            tvUrl: '',
            scene: null,
            canvas: null,
            camera: null,
            engine: null,
            RID: null,
            roomId: null,
            uid: null,
            bgc: null,
            loadedAssets: 0,
            neededAssets: 99,
            exhibitionData: {},
            themeData: {},
            showGround: false,
            groundScale: 1,
            default_exhibition_data : {
                address: '',
                email: '',
                link: '',
                phone: '',
                signboardUrl: '',
                title: '',
                introduction:'',
                roomName: '',
            },
            default_theme_data : {
                description: '',
                key: '',
                name: '',
            },
            meshData: [],
            keyInputs: {},
            characterDestination: {
                distance:0,
                target:null,
            },
            teleportMesh: [],
            teleportTypeName: [],
            collisionTypeName: [],

            //介面
            popUpCanvasToggle: false,
            boardToggle: false,

            //介面資料
            attachedWebsite: '',
            showMessage: 0,
            menuToggle: false,
            menuState: 0,
            channel:"chat",
            chatMessage: '',
            form: {
                firstName:'',
                lastName:'',
                email:'',
                message:'',
            },
            chatroomData: [],
            MENU_STATE: Object.freeze({
                'INTRO': 0,
                'CHAT': 1,
                'CONTACT': 2,
                'ACCOUNT_INFO': 3,
                'PLAYER_ID': 4,
            }),
            MESSAGE_STATE: Object.freeze({
                'FAIL': 1,
                'SUCCESS': 2,
                'WARN': 3,
            }),
            systemData:[],
            playerID:null,
            attachedBoardImg: null,


        }
    },
    watch:{
        playerID(newPlayerID) {
            window.localStorage.setItem('playerID', newPlayerID);
        }
    },
    methods:{
        ...mapActions(['setLogout']),
        async init(){
            this.uid = window.localStorage.getItem('userId');
            this.RID = this.$route.query.id;
            this.roomId = this.$route.query.room;
            
            // 取得資料
            await this.getMeshData(this.roomId,this.RID);

            //整理引擎
            await this.setEngine()
            //整理場景
            await this.setScene();
 
            //整理物件
            //await this.setMeshes(this.meshData);

            await this.loadPlayground();

            
            //建立環境
            await this.setEnvMap(this.bgc);

            //建立角色
            await this.setCharacter();

            this.taggleEvent();
            
            // Inspector.Show(this.scene, {
            //     embedMode: true
            // });
        },
       
        async setEngine(){
            console.log('setEngine');
            this.canvas = document.getElementById('indoor-scene');
            this.engine = await new Babylon.Engine(this.canvas, true, {preserveDrawingBuffer: true, stencil: true }, true);
            this.loadingScreen = new utils.CustomLoadingScreen();
            this.engine.loadingScreen = this.loadingScreen;//disable babylon's default loading effect
            this.engine.runRenderLoop(()=>{
                this.scene.render();
                this.engine.resize();
            })
        },
        async setCamera(){
            console.log('setCamera');
            // this.camera = new Babylon.ArcRotateCamera('playerCamera',-(Math.PI/2), Math.PI/3, 50, this?.character||new Babylon.Vector3.Zero(),scene, true);
            this.camera = new Babylon.ArcRotateCamera("playerCamera", 3 * Math.PI / 2, Math.PI / 3, 30, new Babylon.Vector3(0, 3, 0), this.scene);
            this.camera.lowerRadiusLimit = 1;
            this.camera.upperRadiusLimit = 8;
            this.camera.minZ = .10;
            this.camera.radius = 100;
            this.camera.checkCollisions = false;
            this.camera.attachControl(this.canvas,true);
            this.camera.maxCameraSpeed = 10
            this.camera.inputs.removeByType("ArcRotateCameraKeyboardMoveInput");
            this.camera.radius = this.camera.radius * 1 ;

            window.camera = this.camera;
        },
        async setScene(){
            console.log('setScene');
            
            this.scene = new Babylon.Scene(this.engine);
            //設定相機
            await this.setCamera();

            await this.setBasicMeshes();
            await this.setBasicMaterial();
            await this.setLightAndShadows();
            //啟動物理
            const ammoModule = await new Ammo();
            this.scene.enablePhysics(new Babylon.Vector3(0, -9.81, 0), new Babylon.AmmoJSPlugin(true, ammoModule)); 
            //this.scene.enablePhysics(new Babylon.Vector3(0, -9.81, 0), new Babylon.CannonJSPlugin(true, 8, cannon));


            const gravity = new Babylon.Vector3(0, -9.81, 0);
            this.scene.gravity = gravity;
            this.scene.collisionsEnabled = true;
            this.scene.workerCollisions = true;

            //整理物件
            await this.setMeshes(this.meshData);
            
            this.hl = new Babylon.HighlightLayer("hl1", this.scene);

            this.scene.registerBeforeRender(()=>{
                if(this.loadedAssets === this.neededAssets){
                    this.loading = false;
                }
                //this.cameraRadius = this.camera.radius;
                if(this.renderFrames<30){
                    this.renderFrames++;
                }else{
                    this.scene.renderTargetsEnabled = false;
                }
            
            });
            this.scene.actionManager = new Babylon.ActionManager(this.scene);
            this.keyInputs = {}; // Initialize keyInputs as an empty object
            this.scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyDownTrigger, (evt) => {
                this.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            }));
            this.scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyUpTrigger, (evt) => {
                this.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            }));
            this.scene.onPointerObservable.add((pointerInfo)=>{
                if(pointerInfo.type===Babylon.PointerEventTypes.POINTERTAP){
                    if(!pointerInfo.pickInfo) return;
                    this.interactWithMesh(pointerInfo?.pickInfo?.pickedMesh||null);
                }
            });

            let helper = this.scene.createDefaultEnvironment({
                skyboxSize: 50,
                createSkybox: false,
                createGround: false,
                cameraExposure: 1,
                cameraContrast: 1.5,    
            });
            
            window.scene = this.scene;
            
        },

        async setBasicMeshes(){
            console.log("setBasicMeshes");
            await new Promise((resolve, reject) => {
                Babylon.SceneLoader.ImportMesh("", this.baseURL + 'static/models/component/model/', 'Geo.glb', this.scene, (container) => {
                    console.log("ImportMesh Geo Done");
                    container.forEach((e, index) => {
                        e.setEnabled(false);
                    });
                    resolve();
                });
            });
           
        },
        async setBasicMaterial(){
            this.basicMaterials = [];
            console.log('setBasicMaterial');
            await new Promise((resolve, reject) => {
                Babylon.SceneLoader.ImportMesh('', this.baseURL+'static/models/material/', 'Material.glb', this.scene, (container)=>{
                    console.log("ImportMaterial Done");
                    for(let i=0;i<container.length;i++){
                        let materialName = container[i].name;
                        if(materialName.includes("Concrete")||materialName.includes("Floor")||materialName=='Grass-1'||materialName.includes("Metal")||materialName=='Plastic-1'||materialName.includes('Wood')||materialName.includes('Marble')||materialName=='Leather-1'){
                            if(container[i].material){
                                this.basicMaterials.push(container[i].material);
                                container[i].setEnabled(false);
                            }
                        }
                    }
                    resolve();
                });
            });
        },
        //新的取資料
        async getMeshData(roomId,RID){ 
            console.log('getMeshData');
            const res = await getSellerExhibition({}, RID);
            const sceneUrl = res.data.data.rooms[roomId-1].sceneUrl
            this.exhibitionData = res?.data?.data?.sellerProfile || default_exhibition_data;
            this.themeData = res?.data?.data?.theme || default_theme_data;
            this.exhibitionData.signboardUrl = res?.data?.data?.signboardUrl || '';
            this.exhibitionData.title = res?.data?.data?.title || '';
            this.exhibitionData.roomName = res?.data?.data?.rooms[roomId-1].name || '';
            this.exhibitionData.introduction = res?.data?.data?.introduction || '';
            
            try {
                const res = await axios.get(sceneUrl, {
                    transformRequest: (data, headers) => {
                        delete headers.common['Authorization'];
                    }

                });
                console.log(res.data);

                let mesh = res.data.meshData;
                if(res.data.showGround && res.data.groundScale){
                    this.showGround = res.data.showGround;//取得所有模型資料
                    this.groundScale = res.data.groundScale;
                }
                
                this.meshData = res.data.meshData;
                
                this.neededAssets = mesh.length;
                this.bgc = res.data.bgc
                console.log('getSellerExhibitions:'+this.loadedAssets + ' / ' + this.neededAssets);
                

            } catch (error) {
                if (error.response) {
                    console.log(error);
                    //this.backToEntrance();
                }
            }
        },

        async setMeshes(meshData){
            console.log('setMeshes');
            meshData.forEach((e,index)=>{ //看mesh裡面有什麼
                
                if(index+1 == meshData.length)this.$store.dispatch('loader/hide');
                if (e.type === 'cus') {
                    console.log('custom:' , e);
                    Babylon.SceneLoader.ImportMesh('', this.hostUrl+e.url,e.fileName,this.scene,(container)=>{
                        let thisMesh = container.filter(e=>e.name===('__root__'))[0];
                        thisMesh.rotationQuaternion = null;
                        thisMesh.position.y = e.position.y;
                        thisMesh.position.x = e.position.x;
                        thisMesh.position.z = e.position.z;
                        thisMesh.rotation.y = e.rotation.y;
                        thisMesh.rotation.x = e.rotation.x;
                        thisMesh.rotation.z = e.rotation.z;
                        thisMesh.scaling.y = e.scaling.y;
                        thisMesh.scaling.x = e.scaling.x*-1;
                        thisMesh.scaling.z = e.scaling.z;
                        //thisMesh.checkCollisions = true;
                        this.loadedAssets++;
                        container.forEach(ele=>{
                            this.shadowGenerator.getShadowMap().renderList.push(ele);
                            this.shadowGenerator.addShadowCaster(ele);
                            ele.url = e.url;
                        })
                        
                        //vm.scene.stopAllAnimations();
                    },null,(error)=>{
                        this.loadedAssets++;
                    });
                } else if (e.type === 'notGeo') {
                    console.log('notGeo',e);
                    if(e.modelType === 'Teleport'){
                        this.loadedAssets++;
                        return;
                    }
                    if(e.modelType === 'Collision'){
                        this.loadedAssets++;
                        return;
                    }
                    Babylon.SceneLoader.ImportMesh('', e.url, e.fileName, this.scene, (container) => {
                        const rootMesh = container.find(e => e.name === '__root__');
                        const imgMesh = container.find(e => e.name.includes('UploadHere'));
                        const colorMeshs = container.filter(e => e.name.includes('Color') || e.name.includes('color'));
                        if (imgMesh && e.imgMaterial) {
                            const imgMat = new Babylon.StandardMaterial('', this.scene);
                            imgMat.diffuseTexture = new Babylon.Texture(e.imgMaterial, this.scene);
                            imgMat.emissiveTexture = new Babylon.Texture(e.imgMaterial, this.scene);
                            imgMat.diffuseTexture.vScale = -1;
                            imgMat.emissiveTexture.vScale = -1;
                            imgMesh.material = imgMat;
                        }
                        if (colorMeshs.length > 0) {
                            colorMeshs.forEach(item => {
                                item.material = this.basicMaterials[e.outputMaterial];
                            });
                        } else {
                            const colorMat = new Babylon.StandardMaterial('', this.scene);
                            colorMat.diffuseColor = Babylon.Color3.FromHexString(e.color);
                            colorMeshs.forEach(item => {
                                item.material = colorMat;
                            });
                        }
                        rootMesh.rotationQuaternion = null;
                        rootMesh.position = new Babylon.Vector3(e.position.x, e.position.y, e.position.z);
                        rootMesh.rotationQuaternion = Babylon.Quaternion.RotationYawPitchRoll(e.rotation.y, e.rotation.x, e.rotation.z);
              
                        rootMesh.scaling = new Babylon.Vector3(e.scaling.x * -1, e.scaling.y, e.scaling.z);
                        this.loadedAssets++;
                        container.forEach(ele => {
                            if (ele) {
                                ele.videoLink = e.videoLink;
                                ele.boardImg = e.imgMaterial;
                                ele.website = e.website;
                                this.shadowGenerator.getShadowMap().renderList.push(ele);
                                this.shadowGenerator.addShadowCaster(ele);
                            }
                        });
                    }, null, (error) => {
                        this.loadedAssets++;
                    });
                } else if (e.type === 'Geo') {
                    console.log('Geo',e);
                    const container = new Babylon.Mesh(e.parentName, this.scene);
                    const cloneMesh = this.scene.getMeshByName(e.childName).clone();
                    cloneMesh.name = e.parentName;
                    cloneMesh.setEnabled(true);
                    cloneMesh.receiveShadows = true;
                    if (e.color) {
                        const colorMat = new Babylon.StandardMaterial('', this.scene);
                        colorMat.diffuseColor = Babylon.Color3.FromHexString(e.color);
                        cloneMesh.material = colorMat;
                    } else {
                        cloneMesh.material = this.basicMaterials[e.outputMaterial];
                    }
                    this.shadowGenerator.getShadowMap().renderList.push(cloneMesh);
                    this.shadowGenerator.addShadowCaster(cloneMesh);
                    container.childName = e.parentName;
                    container.addChild(cloneMesh);
                    container.name = e.parentName;
                    container.position = new Babylon.Vector3(e.position.x, e.position.y, e.position.z);
                    container.rotationQuaternion = Babylon.Quaternion.RotationYawPitchRoll(e.rotation.y, e.rotation.x, e.rotation.z);
                    container.scaling = new Babylon.Vector3(e.scaling.x * -1, e.scaling.y, e.scaling.z);
                    this.loadedAssets++;
                }
                
            })

        },

        async setLightAndShadows(){
            
            let hLight = new Babylon.HemisphericLight('hLight', new Babylon.Vector3(1,1,1), this.scene);
            hLight.intensity = 1;
            let light = new Babylon.DirectionalLight("light", new Babylon.Vector3(0, -2, 0), this.scene);
            light.intensity = 2;
            light.position = new Babylon.Vector3(0, 50, 0);

            this.shadowGenerator = new Babylon.ShadowGenerator(1024, light);
            this.shadowGenerator.useBlurExponentialShadowMap = false;
            this.shadowGenerator.useKernelBlur = false;
            this.shadowGenerator.blurKernel = 64;
            this.shadowGenerator.usePoissonSampling = false;
            this.shadowGenerator.getShadowMap().refreshRate = 5;
            
        },
        async loadPlayground(){
            console.log('loadPlayground');
            var darkgray = new Babylon.StandardMaterial("darkgray", this.scene);
            darkgray.diffuseColor = new Babylon.Color3(0.1, 0.1, 0.1);
            darkgray.specularColor = new Babylon.Color3(1, 1, 1);
            darkgray.Ambient =  new Babylon.Color3(0.1, 0.1, 0.1);
            var ground = Babylon.MeshBuilder.CreateGround(
                "ground",
                { width: 100, height: 100 },
                this.scene
            );
            ground.isVisible = this.showGround;
            ground.ambientColor = new Babylon.Color3(0.5, 0.5, 0.5);
            ground.material = darkgray;		
            ground.scaling.x = this.groundScale;
            ground.scaling.z = this.groundScale;
            this.shadowGenerator.addShadowCaster(ground);
            //設定重力
            ground.physicsImpostor = new Babylon.PhysicsImpostor(ground, 
                Babylon.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 1, restitution: 0 }, this.scene
            );
            this.ground = ground;

        },
        //設定環境
        async setEnvMap(env){
            new Babylon.PhotoDome("hdrSkyBox", `/static/models/scene/${env}/${env}.jpg`,{
                resolution: 32,
                size: 1000
            },this.scene);
        },
        makePhysicsObject(newMeshes, scene, scaling){
            // Create physics root and position it to be the center of mass for the imported mesh
            //var physicsRoot = new Babylon.Mesh("physicsRoot", scene);
            let physicsRoot = Babylon.Mesh.CreateBox("physicsRoot", 6, this.scene);
            console.log((physicsRoot.getBoundingInfo().boundingBox.maximum.y + physicsRoot.getBoundingInfo().boundingBox.minimum.y) / 2);
            physicsRoot.isVisible = false;
            physicsRoot.setPivotPoint(new Babylon.Vector3(0, 0, 0));
            
            newMeshes.forEach((m, i)=>{
                console.log(m)
                physicsRoot.addChild(m)
            })
            
            // Make every collider into a physics impostor
            physicsRoot.getChildMeshes().forEach((m,i)=>{
                m.scaling.x = Math.abs(m.scaling.x)
                m.scaling.y = Math.abs(m.scaling.y)
                m.scaling.z = Math.abs(m.scaling.z)
                m.position.y = 0.4
                m.rotationQuaternion = null;
                m.rotation.y = 3.1;
                m.rotation.z = 0;
                //m.physicsImpostor = new Babylon.PhysicsImpostor(m, Babylon.PhysicsImpostor.NoImpostor, { mass: 2 }, scene);
                // if (i === 4) {
                //     m.physicsImpostor = new Babylon.PhysicsImpostor(m, Babylon.PhysicsImpostor.BoxImpostor, { mass: 1 }, scene);
                // }
               
            })
            physicsRoot.scaling.scaleInPlace(scaling)
            //physicsRoot.physicsImpostor = new Babylon.PhysicsImpostor(physicsRoot, Babylon.PhysicsImpostor.BoxImpostor, { mass: 1,  }, this.scene);

            console.log(physicsRoot);
            return physicsRoot
        },
        // makePhysicsObject(newMeshes, scene, scaling){
        //     // Create physics root and position it to be the center of mass for the imported mesh
        //     var physicsRoot = new Babylon.Mesh("physicsRoot", scene);
        //     //physicsRoot.setPivotPoint(new Babylon.Vector3(0, -1, 0)); // meshes[0] is root mesh in VRoid; meshes[8] is body mesh.
        //     //newMeshes[0].setPivotPoint(new Babylon.Vector3(0, 1, 0))
            
        //     newMeshes.forEach((m, i)=>{
        //         console.log(m)
        //         physicsRoot.addChild(m)
            
        //     })
        //     let pointer = 0;
            
        //     // Make every collider into a physics impostor
        //     physicsRoot.getChildMeshes().forEach((m,i)=>{
        //         m.scaling.x = Math.abs(m.scaling.x)
        //         m.scaling.y = Math.abs(m.scaling.y)
        //         m.scaling.z = Math.abs(m.scaling.z)
        //         m.rotationQuaternion = null;
        //         m.rotation.y = 0;
        //         m.physicsImpostor = new Babylon.PhysicsImpostor(m, Babylon.PhysicsImpostor.NoImpostor,{ mass: 0  }, scene);
                
        //         if (i === 11) {
        //             console.log(m.name);
        //             console.log((m.getBoundingInfo().boundingBox.maximum.y + m.getBoundingInfo().boundingBox.minimum.y) / 2);
        //             pointer = (m.getBoundingInfo().boundingBox.maximum.y + m.getBoundingInfo().boundingBox.minimum.y) / 2
        //             //m.physicsImpostor = new Babylon.PhysicsImpostor(m, Babylon.PhysicsImpostor.BoxImpostor, { mass: 0 }, scene);
        //         }
                
        //     })
        //     // Scale the root object and turn it into a physics impsotor
        //     //physicsRoot.setPivotPoint(new Babylon.Vector3(0, -pointer, 0));
        //     //physicsRoot.position = new Babylon.Vector3(0, pointer, 0);

        //     physicsRoot.scaling.scaleInPlace(scaling)
        //     //physicsRoot.physicsImpostor = new Babylon.PhysicsImpostor(physicsRoot, Babylon.PhysicsImpostor.MeshImpostor, { mass: 0 }, scene);        
        //     console.log(physicsRoot);
        //     return physicsRoot
        // },
        
        async setCharacter(){
            console.log("setCharacter");
            const locationParam = this.$route.query.location;
            let customLocationX = 0;
            let customLocationY = 3;
            let customLocationZ = -5;
            if (locationParam) {
                const customLocation = locationParam.split(',').map(Number);
                customLocationX = customLocation[0];
                customLocationY = customLocation[1] + 3;
                customLocationZ = customLocation[2];
            }
            console.log([customLocationX,customLocationY,customLocationZ]);
            
            const complexModel = (await Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'box_character_walking.glb', this.scene)).meshes;
            //const complexModel = (await Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'viewer_avatar.glb', this.scene)).meshes;
           
            console.log(complexModel);
            console.log('create ellipse');
            // Convert to physics object and position
            var physicsRoot = this.makePhysicsObject(complexModel, this.scene, 0.2)
            
            const cameraLockMesh = new Babylon.Mesh("cameraLock", this.scene);
            cameraLockMesh.position.y = 10;
            cameraLockMesh.parent = physicsRoot;
            //this.character = complexModel[0];
            //physicsRoot.setPivotPoint(new Babylon.Vector3(0, 0, 0));
            //physicsRoot.physicsImpostor = new Babylon.PhysicsImpostor(physicsRoot, Babylon.PhysicsImpostor.MeshImpostor, { mass: 1, friction: 0, restitution: 0 }, this.scene);
            //physicsRoot.setPivotPoint(new Babylon.Vector3(0, -2.05, 0));
            //physicsRoot.position = new Babylon.Vector3(0, 2.05, 0);
            
            physicsRoot.physicsImpostor = new Babylon.PhysicsImpostor(physicsRoot, Babylon.PhysicsImpostor.BoxImpostor, { mass: 2  }, this.scene);

            this.character = physicsRoot;
            
            //this.character.physicsImpostor = new Babylon.PhysicsImpostor(this.character, Babylon.PhysicsImpostor.MeshImpostor, { mass: 1,  }, this.scene);


            this.camera.lockedTarget = cameraLockMesh;
            //this.camera.target = this.cameraLocker;
            
            this.character.position = new Babylon.Vector3(customLocationX,customLocationY,customLocationZ);
            this.character.actionManager = new Babylon.ActionManager(this.scene);
            
            this.scene.onBeforeRenderObservable.add(() => {
                const moveSpeed = 0.1;
                const rotateSpeed = 0.02;
                const rotateRatio = !this.invertRotation ? 0.4 : 0.4;
  
                if (this.keyInputs["w"]) {
                    this.characterDestination.distance = 0;
                    this.character.translate(Babylon.Axis.Z, moveSpeed + 0.2, Babylon.Space.LOCAL);

                }
                if (this.keyInputs["s"]) {
                    this.characterDestination.distance = 0;
                    this.character.translate(Babylon.Axis.Z, -moveSpeed - 0.2 , Babylon.Space.LOCAL);
                }
                if (this.keyInputs["a"]) {
                    this.characterDestination.distance = 0;
                    this.character.moveWithCollisions(this.camera.getDirection(new Babylon.Vector3( -moveSpeed, 0, 0)));

                }
                if (this.keyInputs["d"]) {
                    this.characterDestination.distance = 0;
                    this.character.moveWithCollisions(this.camera.getDirection(new Babylon.Vector3(moveSpeed, 0, 0)));
                    //console.log(this.character);
                }
                if (this.keyInputs["q"] || this.keyInputs["ArrowLeft"]) {
                    this.characterDestination.distance = 0;
                    this.character.rotate(Babylon.Vector3.Up(), -rotateSpeed);
                    this.camera.alpha -= 0.05 * (this.invertRotation ? rotateRatio : -rotateRatio);
                    //this.camera.alpha += 0.03;
                }
                if (this.keyInputs["e"] || this.keyInputs["ArrowRight"]) {
                    this.characterDestination.distance = 0;
                    this.character.rotate(Babylon.Vector3.Up(), +rotateSpeed);
                    this.camera.alpha += 0.05 * (this.invertRotation ? rotateRatio : -rotateRatio);
                }
                if (this.keyInputs["["] || this.keyInputs["ArrowUp"]) {
                    this.camera.beta -= this.camera.beta > 0.7 ? 0.05 : 0;
                }
                if (this.keyInputs["]"] || this.keyInputs["ArrowDown"]) {
                    this.camera.beta += this.camera.beta < 1.3 ? 0.05 : 0;
                }
            });
        },

        interactWithMesh(mesh){
            if(!mesh) return;
            const name = mesh.name;
            console.log(mesh);
            if(name.includes('Monitor')|| name.includes('Screen') || name.includes('Board')|| name.includes('Display') && mesh.website){
                //this.tvToggle = true;
                if(mesh.website.includes(".mp4")){
                    this.tvToggle = true;
                    this.tvUrl = mesh.website;
                   
                }else if (mesh.website.includes("youtube") || mesh.website.includes("youtu.be")) {
                    this.tvToggle = true;
                    this.tvUrl = 'https://www.youtube.com/embed/'+this.getId(mesh.website)+'?modestbranding=1&autohide=1&showinfo=0';
                }
                else{
                    this.attachedWebsite = mesh.website;
                    this.attachedBoardImg = mesh.boardImg;
                    this.boardToggle = true;
                }


            }
            if(name.includes('lowpoly') && mesh.url || (mesh.type==='Customized') && mesh.url){
                this.popUpCanvasToggle = true;
                this.initPopUpScene({
                    url: '',
                    fileName: mesh.url,
                    modelName: mesh.modelName||'Unnamed',
                })
            }
            if(name.includes('Counter')||name.includes('Info')||name.includes('info')){
                this.toggleForm();
            }
        },

        confirmLogout(){
            if(this.login){
                this.setLogout();
                this.$message({
                    showClose: true,
                    message: `Logout`,
                    type: 'success',
                    duration: 1500,
                    onClose:()=>{
                        this.$store.dispatch('loader/show');
                        this.$router.push("/landing");
                    }
                });
            }
        },
        taggleEvent(){
            console.log("taggleEvent");
            console.log(this.teleportTypeName);
            let mesh = this.meshData;
            mesh.forEach((e,index)=>{ 
                if(e.type=='notGeo' && e.modelType === 'Teleport' ){
                    this.teleportTypeName.push("tel_"+index);
                    var box = Babylon.Mesh.CreateBox("tel_"+index, 2, this.scene);
                    box.material = new Babylon.StandardMaterial("Mat", this.scene);
                    box.position = new Babylon.Vector3(5, 0, -5);
                    box.position.y = e.position.y;
                    box.position.x = e.position.x;
                    box.position.z = e.position.z;
                    box.rotation.y = e.rotation.y;
                    box.rotation.x = e.rotation.x;
                    box.rotation.z = e.rotation.z;
                    box.scaling.y = e.scaling.y;
                    box.scaling.x = e.scaling.x*-1;
                    box.scaling.z = e.scaling.z;
                    box.sendLink = e.sendLink;
                    box.sendLocation = e.sendLocation;
                    box.isVisible = false;
                }else if(e.type=='notGeo' && e.modelType === 'Collision' ){
                    this.collisionTypeName.push("col_"+index);
                    var box = Babylon.Mesh.CreateBox("col_"+index, 2, this.scene);
                
                    box.isVisible = false;
                    box.position.y = e.position.y;
                    box.position.x = e.position.x;
                    box.position.z = e.position.z;
                    box.rotation.y = e.rotation.y;
                    box.rotation.x = e.rotation.x;
                    box.rotation.z = e.rotation.z;
                    box.scaling.y = e.scaling.y;
                    box.scaling.x = e.scaling.x*-1;
                    box.scaling.z = e.scaling.z;
                    // box.physicsImpostor = new Babylon.PhysicsImpostor(box, Babylon.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 1, restitution: 0 }, this.scene)
                 
                  
                }
            });

            //this.character.actionManager = new Babylon.ActionManager(this.scene);
            //處理傳送
            this.teleportTypeName.forEach(element => {
                let targetMesh_geo = this.scene.getMeshByName(element)

                let action = new Babylon.ExecuteCodeAction(
                    {
                        trigger: Babylon.ActionManager.OnIntersectionEnterTrigger, 
                        parameter: targetMesh_geo,
                    },
                    (evt) => {
                        let toggleMesh = evt.additionalData;
                        let sendLink = toggleMesh.sendLink;
                        let sendLocation = toggleMesh.sendLocation;                        
                        let moveToUrl = `${sendLink}&location=${sendLocation}`;
                        window.location.replace(moveToUrl);
                    }
                );

                this.character.actionManager.registerAction(action);
                console.log(this.character);
            });

            //碰撞
            console.log(this.collisionTypeName);
            this.collisionTypeName.forEach(element => {
                let targetMesh_geo = this.scene.getMeshByName(element)
                targetMesh_geo.physicsImpostor = new Babylon.PhysicsImpostor(targetMesh_geo, Babylon.PhysicsImpostor.BoxImpostor, { mass: 0 }, this.scene)

                targetMesh_geo.physicsImpostor.registerOnPhysicsCollide(this.character.physicsImpostor, function(main, collided) {
                    console.log("collide");
                    main.setLinearVelocity(Babylon.Vector3.Zero());
                    main.setAngularVelocity(Babylon.Vector3.Zero());

                });
            });
    
        },
        //  介面操作
        closePopScene(){
            this.loading = false;
            this.popUpCanvasToggle = false;
            this.engine.runRenderLoop(()=>{
                this.scene.render();
                this.engine.resize();
            });
            this.popEngine.stopRenderLoop();
            this.popEngine.dispose();
            setTimeout(()=>{
                this.loading = false;
            }, 750);
        },
        switchMenuState(state, toggleBehavior=true){
            this.extendMenu = false;
            if(this.menuState===state&&this.menuToggle&&toggleBehavior){
                this.menuToggle = false;
                return;
            }
            switch (state) {
                case this.MENU_STATE.BLOCK:
                    break;
                case this.MENU_STATE.CHAT:
                    break;
                case this.MENU_STATE.CONTACT:
                    break;
                case this.MENU_STATE.PLAYER_ID:
                    break;
            }
            this.menuState = state;
            this.menuToggle = true;
        },
        shotPhoto(){
            this.extendMenu = false;
            Babylon.Tools.CreateScreenshot(engine, camera, { width: window.innerWidth, height: window.innerHeight }, function (data) {
                this.screenShotSrc = data;
            });
        },
        //還要研究
        initPopUpScene(data){
            this.engine.stopRenderLoop();//stop main scene rendering;
            let canvas = document.getElementById('pop-up-canvas');
            this.popEngine = new Babylon.Engine(canvas, true);
            let scene = new Babylon.Scene(this.popEngine);
            scene.name = 'PopScene';
            window.scenes = scene;

            let camera = new Babylon.ArcRotateCamera('arcCameraPop', 0, 0, 7, new Babylon.Vector3(0,0,0), scene);
            camera.attachControl(canvas, true);
            camera.lowerRadiusLimit = 2;
            camera.beta = Math.PI/3;
            camera.alpha = Math.PI/2;
            camera.upperRadiusLimit = 15;
            camera.wheelDeltaPercentage = 0.01;

            let pixelRatio = window.devicePixelRatio;
            // Set the render engine to scale properly
            engine.setHardwareScalingLevel(1 / pixelRatio); // This makes the scene and GUI clear on a HDPI display, but its no longer the correct size.

            let light = new Babylon.HemisphericLight('',new Babylon.Vector3.Zero(), scene);
            light.intensity = 1.2;

            let helper = scene.createDefaultEnvironment({
                groundShadowLevel: 0,
                groundColor: new Babylon.Color3.FromHexString('#fff'),
                skyboxColor: new Babylon.Color3.FromHexString('#fff'),
                skyboxSize: 50,   
            });
            Babylon.SceneLoader.ImportMesh('', data.url, data.fileName, scene, (container)=>{
                let rootMesh = container.filter(e=>e.name==='__root__')[0];
                rootMesh.setPivotPoint(new Babylon.Vector3.Zero());
                rootMesh.position.set(0,0,0);
                container.forEach(e=>{
                    if(e.name.includes('lowpoly')){
                        e.setEnabled(false);
                    }
                })
                //scene.stopAllAnimations();
            },(prog)=>{
                console.log(prog);
            },(error)=>{
                console.log(error);
            })
            this.popEngine.runRenderLoop(()=>{
                scene.render();
                this.popEngine.resize();
            })
            scene.onPointerPick = (event, pickResult)=>{
                if(!pickResult.hit) return;
                let name = pickResult.pickedMesh.name;
                // let anim = null;
                if(name.includes('dot')){
                    let anim = scene.getAnimationGroupByName((name.split('_')[0]).replace('txt','Anim'));
                    if(anim && !anim.isPlaying){//Avoid animation playing while clicking trigger;
                        if(anim.playback){
                            anim.start(false, 1, anim.to, anim.from, true);
                        }else{
                            anim.start(false, 1, anim.from, anim.to, true);
                        }
                        anim.onAnimationEndObservable.addOnce(()=>{
                            anim.stop();
                        })
                        anim.playback = anim.playback ? false:true;
                    }
                    /* 動畫聚焦 */
                    // this.animateCameraToPosition(camera, 1, 1.8, pickResult.pickedPoint);
                    // anim.onAnimationEndObservable.addOnce(()=>{
                    //     ele.goToFrame(ele.to);
                    //     ele.playback = anim.playback?false:true;
                    // });
                }
            }
        },
        getId(url) {
            if(!url || url===undefined) return;
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            let match = url.match(regExp);

            return (match && match[2].length === 11) ? match[2] : null;
        },
        clearUI(){
            this.popUpCanvasToggle = this.tvToggle = this.introToggle = this.popUpCanvasToggle = this.formToggle = this.boardToggle = false;
        },
        async sendMessage(){
            this.clearUI();
            const vm = this;
            vm.getUserData();
            await sendMail(this.form, this.$route.query.id).then((res)=>{
                vm.showMessage = this.MESSAGE_STATE.SUCCESS;
                setTimeout(()=>{
                    vm.showMessage = 0;
                }, 1500)
            }).catch(err=>{
                vm.showMessage = this.MESSAGE_STATE.FAIL;
                setTimeout(()=>{
                    vm.showMessage = 0;
                }, 1500)
            })
        },
        keydownEvent(e){
            if(e.keyCode===13){
                if(!this.chatMessage.length) return;
                this.sendChatMessage();
            }
        },
        sendChatMessage(){
            this.updateChatMessage({
                name: this.nickname,
                messages: this.chatMessage,
                room: this.$route.query.id
                //theme: this.currentTheme,
            });
        },
        backToEntrance(){
            this.$router.push({name: 'Entrance'});
        },
        setPlayerID(){
            localStorage.setItem('playerID', this.playerID);
        },
    },
    watch:{
        loadedAssets(){
            console.log('loadedAssets',this.loadedAssets);
            // if(this.loadedAssets == this.meshData.length){
            //     this.taggleEvent();
            // }
        },
        
    },
    created(){
        console.log("ExhibitionNew created");
    },
    mounted(){
        this.init();
       
       
    },
    computed:{
        currentRoom(){
            return this.$route.query.room;
        },
        userId(){
            return localStorage.getItem("userId");
            // return this.$route.query.uid;
        },
        login(){
            const uid = localStorage.getItem('userId');
            const token = localStorage.getItem('token');
            if(uid&&token){
                return true;
            }
            else{
                return false;
            }
        },
        menuTitle(){
            if(this.menuState===this.MENU_STATE.INTRO){
                return "Block Intro";
            }
            else if(this.menuState===this.MENU_STATE.CONTACT){
                return "Contact";
            }
            else if(this.menuState===this.MENU_STATE.CHAT){
                return "Chat";
            }
            else if(this.menuState===this.MENU_STATE.ACCOUNT_INFO){
                return "Account Info";
            }
            else{
                return "";
            }
        }
        
    },
    destroyed(){
        if(this.engine){
            this.engine.stopRenderLoop();
            this.engine.dispose();
        }
        if(this.popEngine){
            this.popEngine.stopRenderLoop();
            this.popEngine.dispose();
        }
    },
}
</script>
